import { useEffect, useRef } from "react";
import { useWeGanttConsumer } from "..";
import Moment from "../../../modules/Moment/Moment";
import { clsx } from "../../../modules/Utilkit/Utilkit";
import { Vector2D } from "../../../modules/Vecto/Vecto";
import { default as editSplit } from "../modules/calculateTask";

const Split = ({ split, rowRef, task }) => {
  const { range, workingHours, cellWidth, days, holidays, fontSize, TaskCell, onMove, onResize, onTaskDoubleClick, onTaskContextMenu, viewMode } = useWeGanttConsumer();

  const bar = useRef(null);
  const barStyle = useRef(null);
  const labelRef = useRef(null);
  const splitDays = split.duration / workingHours % 1 == 0 ? split.duration / workingHours : Math.floor(split.duration / workingHours);

  const divider = viewMode === 'weeks' ? 7 : 1;
  const durationDays = split.duration / workingHours;
  const startPoint = Moment(range.from).duration(split.start);
  const weeksCovered = Math.ceil((durationDays + (startPoint % divider)) / divider);

  let left, width;
  if (viewMode === 'weeks') {
    left = (Moment(range.from).duration(split.start, { size: 'weeks' }) - 2) * cellWidth;
    width = Moment(split.start).duration(split.end, { size: 'weeks' }) * cellWidth;
  } else {
    left = (Math.floor(startPoint / divider) * cellWidth);
    width = weeksCovered * cellWidth;
  }

  let isMove = useRef(false);
  let isResize = useRef(false);
  let clickPos = useRef(0);

  const index = task.dates.findIndex((date) => date.id === split.id);
  const minX = useRef(0);
  const maxX = useRef(0);

  const label = TaskCell ? <TaskCell splitTask={ split } task={ task } /> : split.label;
  const labelIsOut = split.label && (split.label?.length * (fontSize / 2)) + ((fontSize / 2) * 8) > width;

  const handleMouseEnter = (event) => {
    event.currentTarget.style.filter = 'brightness(120%)';
    barStyle.current.style.zIndex = 20;
    if (labelRef.current) {
      labelRef.current.style.backgroundColor = 'white';
      labelRef.current.style.boxShadow = '0 0 4px 0 rgba(0, 0, 0, 0.4)';
      labelRef.current.style.zIndex = 20;
    }
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.style.filter = 'brightness(100%)';
    barStyle.current.style.zIndex = 10;
    if (labelRef.current) {
      labelRef.current.style.backgroundColor = 'transparent';
      labelRef.current.style.boxShadow = 'none';
      labelRef.current.style.zIndex = 10;
    }
  };

  const handleMove = (event) => {
    event.preventDefault();
    if (event.button !== 0 || !split.isDraggable || viewMode !== 'days') return;
    barStyle.current.style.zIndex = 20;
    clickPos.current = event.clientX - bar.current.offsetLeft;
    isMove.current = true;
  };

  const handleDoubleClick = (event) => {
    event.preventDefault();
    const parent = rowRef.current;
    const position = new Vector2D().point(event, parent).div(cellWidth).floor();
    const selDate = new Moment(range.from).add(position.x()).value();
    onTaskDoubleClick({ task: { ...task, split, selDate }, event });
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    const parent = rowRef.current;
    const position = new Vector2D().point(event, parent).div(cellWidth).floor();
    const selDate = new Moment(range.from).add(position.x()).value();
    onTaskContextMenu({ task: { ...task, split, selDate }, event });
  };

  const handleResize = (event) => {
    event.preventDefault();
    if (!split.isResizable) return;
    barStyle.current.style.zIndex = 20;
    clickPos.current = event.clientX - bar.current.offsetLeft;
    isResize.current = true;
  };

  const update = () => {
    if (index > 0) {
      const prev = task.dates[ index - 1 ];
      const prevEnd = new Moment(Moment(prev.start).end(prev.duration / workingHours, holidays)).add(1).value();
      minX.current = (Moment(range.from).duration(prevEnd) * cellWidth) + (cellWidth * 0.25);
    } else {
      minX.current = 0;
    }

    if (index < task.dates.length - 1) {
      const next = task.dates[ index + 1 ];
      maxX.current = (Moment(range.from).duration(next.start) * cellWidth) - (cellWidth * 0.25);
    } else {
      maxX.current = days.length * cellWidth;
    }
  };

  useEffect(() => {
    update();
    const handleMouseUp = (e) => {
      if (!barStyle.current) return;
      barStyle.current.style.zIndex = 10;
      if (isMove.current) {
        e.preventDefault();
        isMove.current = false;
        try {
          const pos = new Vector2D(bar.current.offsetLeft).div(cellWidth).floor();
          let newStartMoment = Moment(range.from).add(pos.x());

          const newStart = newStartMoment.available(holidays, "prev");
          const newEnd = Moment(newStart).end(split.duration / workingHours, holidays);

          if (newStart !== split.start && onMove) {
            const newSplit = editSplit(workingHours, holidays, task, split, newStart, newEnd, split.duration, "move", false);
            onMove({ ...newSplit, splitID: split.id });
          } else {
            // CLICKED
          }
        } catch (e) {
          console.error(e);
        }
        bar.current.style.left = `${left}px`;
      } else if (isResize.current) {
        e.preventDefault();
        isResize.current = false;

        try {
          const size = Math.floor(bar.current.offsetWidth / cellWidth) + 1;
          const end = new Moment(split.start).end(size);

          const availableEnd = new Moment(end).available(holidays, "next");
          const newLength = new Moment(split.start).duration(availableEnd) + 1;

          if (newLength !== splitDays) {
            const newSplit = editSplit(workingHours, holidays, task, split, split.start, availableEnd, newLength * workingHours, "resize", false);
            onResize({ ...newSplit, splitID: split.id });
            bar.current.style.width = `${width}px`;
          } else {
            bar.current.style.width = `${width}px`;
          }
        } catch (e) {
          bar.current.style.width = `${width}px`;
        }
      }
    };

    const handleMouseMove = (e) => {
      if (isMove.current) {
        const newX = e.clientX - clickPos.current;
        bar.current.style.left = `${newX}px`;
        /*if (newX >= minX.current && (newX + width) <= maxX.current) {
          bar.current.style.left = `${newX}px`;
        } else if (newX < minX.current) {
          bar.current.style.left = `${minX.current}px`;
        } else {
          bar.current.style.left = `${maxX.current - width}px`;
        }*/
      } else if (isResize.current) {
        const newWidth = e.clientX - clickPos.current - bar.current.offsetLeft + width;
        if (newWidth > cellWidth * 3 / 4) {

          bar.current.style.width = `${newWidth}px`;
          /*if (newWidth < maxX.current - bar.current.offsetLeft) {
            bar.current.style.width = `${newWidth}px`;
          } else {
            bar.current.style.width = `${maxX.current - bar.current.offsetLeft}px`;
          }*/
        } else {
          bar.current.style.width = `${cellWidth * 3 / 4}px`;
        }
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [ width, left, days, task ]);

  return (
    <div
      className="absolute flex select-none h-full py-0.5"
      style={ { top: 0, left, width } }
      ref={ bar }
    >
      <div
        ref={ barStyle }
        style={ {
          backgroundColor: split.color || '#3b82f6',
          fontSize: fontSize * 0.8,
          opacity: split.status === 'completed' ? 0.4 : split.status === 'hidden' ? 0 : 1,
          display: (task.hidden || split.hidden) && 'hidden'
        } }
        className={ clsx(
          "w-full duration-75 z-10 flex text-white shadow-sm",
          split.isDraggable && viewMode === 'days' && "cursor-pointer",
          split.status === 'dashed' ? "border-2 border-dashed border-gray-500" : "border border-[#AAA]"
        ) }
        onMouseEnter={ handleMouseEnter }
        onMouseLeave={ handleMouseLeave }
      >
        { split.isResizable && viewMode === 'days' && <div className="w-2 select-none" /> }
        <div
          className="flex-1 flex justify-center items-center select-none "
          style={ { whiteSpace: "nowrap", textOverflow: "ellipsis" } }
          onMouseDown={ handleMove }
          onDoubleClick={ handleDoubleClick }
          onContextMenu={ handleContextMenu }
        >
          { labelIsOut ? (
            <div
              ref={ labelRef }
              className="absolute translate-x-full px-2 text-black select-none pointer-events-none -right-1 rounded-md"
            >
              { label }
            </div>
          ) : (
            label
          ) }
        </div>
        { split.isResizable && viewMode === 'days' && <div className="w-2 cursor-ew-resize" onMouseDown={ handleResize } /> }
      </div>
    </div>
  );
};

export default Split;